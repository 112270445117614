// Typography

$body-font-family: "Open Sans", Arial, sans-serif !default;
$mono-font-family: "SFMono-Regular", menlo, consolas, monospace !default;
$root-font-size: 16px !default; // Base font-size for rems
$body-line-height: 1.4 !default;
$content-line-height: 1.6 !default;
$body-heading-line-height: 1.25 !default;

// Font size
// `-sm` suffix is the size at the small (and above) media query

$font-size-1: 9px !default;
$font-size-1-sm: 10px !default;
$font-size-2: 18px !default; // h4 - uppercased!, h6 not uppercased, text-small
$font-size-3: 16px !default; // h5
$font-size-4: 14px !default;
$font-size-5: 20px !default; // h3
$font-size-6: 28px !default; // h2
$font-size-7: 24px !default;
$font-size-8: 32px !default; // h1
$font-size-9: 36px !default;
$font-size-10: 42px !default;
$font-size-10-sm: 32px !default;

// Colors

$white: #fff !default;
$grey-dk-000: #8993a4 !default;
$grey-dk-100: #6B788E !default;
$grey-dk-200: #505F79 !default;
$grey-dk-250: #354764 !default;
$grey-dk-300: #030C1C !default;
$grey-lt-000: #F5F6F7 !default;
$grey-lt-100: #EBEDF0 !default;
$grey-lt-200: #DFE2E6 !default;
$grey-lt-300: #C2C7D0 !default;
$purple-000: #656FDF !default;
$purple-100: #4552D9 !default;
$purple-200: #3742AE !default;
$purple-300: #2A3284 !default;
$blue-000: #61AADF !default;
$blue-100: #4199D8 !default;
$blue-200: #257AB6 !default;
$blue-300: #285D84 !default;
$green-000: #00CD8E !default;
$green-100: #00A270 !default;
$green-200: #007D57 !default;
$green-300: #00593E !default;
$yellow-000: #F1ECBA !default;
$yellow-100: #DCD156 !default;
$yellow-200: #A49537 !default;
$yellow-300: #7D7531 !default;
$red-000: #EB9CA2 !default;
$red-100: #DD5B66 !default;
$red-200: #D73C49 !default;
$red-300: #AC303A !default;

// Spacing

$spacing-unit: 1rem; // 1rem == 16px

$spacers: (
  sp-0: 0,
  sp-1: $spacing-unit * 0.25,
  sp-2: $spacing-unit * 0.5,
  sp-3: $spacing-unit * 0.75,
  sp-4: $spacing-unit,
  sp-5: $spacing-unit * 1.5,
  sp-6: $spacing-unit * 2,
  sp-7: $spacing-unit * 2.5,
  sp-8: $spacing-unit * 3,
  sp-9: $spacing-unit * 3.5,
  sp-10: $spacing-unit * 4,
) !default;
$sp-1: map-get($spacers, sp-1) !default; // 0.25 rem == 4px
$sp-2: map-get($spacers, sp-2) !default; // 0.5  rem == 8px
$sp-3: map-get($spacers, sp-3) !default; // 0.75 rem == 12px
$sp-4: map-get($spacers, sp-4) !default; // 1    rem == 16px
$sp-5: map-get($spacers, sp-5) !default; // 1.5  rem == 24px
$sp-6: map-get($spacers, sp-6) !default; // 2    rem == 32px
$sp-7: map-get($spacers, sp-7) !default; // 2.5  rem == 40px
$sp-8: map-get($spacers, sp-8) !default; // 3    rem == 48px
$sp-9: map-get($spacers, sp-9) !default; // 3.5  rem == 56px
$sp-10: map-get($spacers, sp-10) !default; // 4  rem == 64px

// Borders

$border: 1px solid !default;
$border-radius: 1px !default;
$border-color: $grey-lt-100 !default;

// Grid system

$gutter-spacing: $sp-6 !default;
$gutter-spacing-sm: $sp-4 !default;
$nav-width: 264px !default;
$nav-width-md: 248px !default;
$nav-list-item-height: $sp-6 !default;
$nav-list-item-height-sm: $sp-8 !default;
$nav-list-expander-right: true;
$content-width: 1080px !default;
$header-height: 60px !default;
$search-results-width: $content-width - $nav-width !default;
$transition-duration: 400ms;

// Media queries in pixels

$media-queries: (
  xs: 320px,
  sm: 500px,
  md: $content-width,
  lg: $content-width + $nav-width,
  xl: 1400px,
) !default;
